import { useEffect, useState } from "react"
import { SelectItemConfiguration, SelectReviewByOption } from "../api"
import { TextStyle } from "../App"
import { LanguageUnitItemConfiguration } from "../Config/Language/Zh/Config/UnitConfig"
import { LanguageDefinitionConfiguration } from "../Config/Language/Zh/Definition"
import { ItemTypeTestConfigurationMap } from "../Config/Language/Zh/Types"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { Data, SetData } from "../Types/Interfaces"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { Modal } from "../Components/Modal"

type SettingsStructureType = Record<number, UnitSettingsStructure>

type UnitSettingsStructure = {
    Id: number,
    Label: string,
    Enabled: boolean,
    Items: Record<number, ItemSettingsStructure>
}

type ItemSettingsStructure = {
    Id: number,
    Label: string
    Enabled: boolean

    New: number,
    Review: number,
    SelectReviewBy: SelectReviewByOption

    Tests: Record<number, TestSettingStructure>
}

type TestSettingStructure = {
    Id: number
    Label: string
    Enabled: boolean
}

export const SelectItemParametersView: React.FC<Data<{ ContextKey: string, UnitTypes: number[], LanguageDefinition: LanguageDefinitionConfiguration }> & SetData> = props => {


    const { UnitItemListing, ItemTypeTestConfiguration, Text } = props.Data.LanguageDefinition

    const [settingsState, setSettingsState] = useState<SettingsStructureType | undefined>(undefined)
    const [loaded, setLoaded] = useState(false)

    const [saved, setSaved] = useState(false)
    const [loadedPreset, setLoadedPreset] = useState(false)

    const [mode, setMode] = useState<"load" | "save" | "custom" | "default">("default")

    const [savePresetLabel, setSavePresetLabel] = useState("")
    const [savePresetView, setSavePresetView] = useState(false)
    const [loadPresetView, setLoadPresetView] = useState(false)
    const [loadPresetLabel, setLoadPresetLabel] = useState("")
    useEffect(() => {

        if (settingsState == undefined) {

            const settingsStructure: SettingsStructureType = {}
            for (var unit of props.Data.UnitTypes) {
                const unitDefn = UnitItemListing.UnitConfigurations[unit]

                settingsStructure[unit] = {
                    Id: unit,
                    Label: Text.Unit[unit],
                    Enabled: false,
                    Items: {}
                }

                for (var itemType of unitDefn.ItemTypes) {

                    settingsStructure[unit]["Items"][itemType] = {
                        Id: itemType,
                        Enabled: false,
                        Label: Text.Item[itemType],
                        New: 0,
                        Review: 0,
                        SelectReviewBy: SelectReviewByOption.Due,
                        Tests: {}
                    }

                    for (var td of ItemTypeTestConfiguration[itemType].TestDefinitions) {

                        settingsStructure[unit]["Items"][itemType]["Tests"][td] = {
                            Id: td,
                            Label: Text.Test[td],
                            Enabled: false
                        }
                    }
                }
            }

            setSettingsState(settingsStructure)
            setLoaded(true)
        }
    }, [settingsState])

    function SetParam<T>(obj: any, key: string, value: T) {
        const mapkey: keyof typeof obj = key
        obj[mapkey] = value

        setSaved(false)
        setSettingsState({ ...settingsState })
    }

    const ConstructSelectItemParameters = () => {
        const selectItemParameters: { [key: string]: { [key: string]: SelectItemConfiguration; }; } = {}

        for (var [unitKey, unitSettings] of Object.entries(settingsState!!)) {
            if (unitSettings.Enabled) {
                selectItemParameters[unitKey] = {}

                for (var [itemKey, itemSettings] of Object.entries(unitSettings.Items)) {
                    if (itemSettings.Enabled) {
                        var itemConf = new SelectItemConfiguration()
                        itemConf.newItems = itemSettings.New
                        itemConf.reviewItems = itemSettings.Review
                        itemConf.selectReviewBy = itemSettings.SelectReviewBy

                        itemConf.testDefinitions = Object.entries(itemSettings.Tests)
                            .filter(([__, testSettings], _) => {
                                return testSettings.Enabled
                            }).map(([_, testSettings]) => testSettings.Id)

                        selectItemParameters[unitKey][itemKey] = itemConf

                    }
                }
            }

            return selectItemParameters
        }

    }


    const CreateSelectItemParameters = () => {
        const selectItemParameters: { [key: string]: { [key: string]: SelectItemConfiguration; }; } = {}

        for (var [unitKey, unitSettings] of Object.entries(settingsState!!)) {
            if (unitSettings.Enabled) {
                selectItemParameters[unitKey] = {}

                for (var [itemKey, itemSettings] of Object.entries(unitSettings.Items)) {
                    if (itemSettings.Enabled) {
                        var itemConf = new SelectItemConfiguration()
                        itemConf.newItems = itemSettings.New
                        itemConf.reviewItems = itemSettings.Review
                        itemConf.selectReviewBy = itemSettings.SelectReviewBy

                        itemConf.testDefinitions = Object.entries(itemSettings.Tests)
                            .filter(([__, testSettings], _) => {
                                return testSettings.Enabled
                            }).map(([_, testSettings]) => testSettings.Id)

                        selectItemParameters[unitKey][itemKey] = itemConf

                    }
                }
            }
        }

        props.SetData(selectItemParameters)
        // localStorage.setItem("selectItemParameters_default", JSON.stringify(selectItemParameters))
        setSaved(true)
    }

    const CreateCustomSettings = () => { setMode("custom") }
    const BeginSavePreset = () => { setSavePresetView(true) }

    const SavePreset = () => {
        setSavePresetView(false)
        setSaved(true)
        const selectItemParameters = ConstructSelectItemParameters()
        localStorage.setItem("selectItemParameters_" + savePresetLabel, JSON.stringify(selectItemParameters))

        var presets = JSON.parse(localStorage.getItem("selectItemParameters_presets_" + props.Data.ContextKey)!!)
        if (presets == null) {
            presets = []
        }
        presets.push(savePresetLabel)
        localStorage.setItem("selectItemParameters_presets_" + props.Data.ContextKey, JSON.stringify(presets))

        props.SetData(selectItemParameters)
    }

    const BeginLoadPreset = () => {
        setLoadPresetView(true)
    }

    const LoadPreset = () => {
        setLoadPresetView(false)
        props.SetData(JSON.parse(localStorage.getItem("selectItemParameters_" + loadPresetLabel)!!))
        setLoadedPreset(true)
    }

    return <ColumnLayout>
        {loadPresetView && <>
            <Modal OnClose={() => setLoadPresetView(false)}>

                <ContentTile>
                    <ColumnLayout>
                        <TextStyle>Load preset</TextStyle>
                        <select onChange={(e) => setLoadPresetLabel(e.target.value)}>
                            {JSON.parse(localStorage.getItem("selectItemParameters_presets_" + props.Data.ContextKey)!!)?.map((key: string, _: number) => {
                                return <option>{key}</option>
                            })}
                        </select>
                        <StyledButton onClick={LoadPreset}>Load</StyledButton>
                    </ColumnLayout>
                </ContentTile>
            </Modal>
        </>}
        {savePresetView && <>
            <Modal OnClose={() => setSavePresetView(false)}>
                <ContentTile>
                    <ColumnLayout>
                        <TextStyle>Label</TextStyle>
                        <input type="text" onChange={(e) => setSavePresetLabel(e.target.value)} />
                        <StyledButton onClick={SavePreset}><TextStyle>Save</TextStyle></StyledButton>
                    </ColumnLayout>
                </ContentTile>
            </Modal>
        </>}
        <StyledButton onClick={BeginLoadPreset}><TextStyle>Load preset</TextStyle></StyledButton>
        <StyledButton onClick={BeginSavePreset}><TextStyle>Save as preset</TextStyle></StyledButton>
        <StyledButton onClick={CreateSelectItemParameters}><TextStyle>Save</TextStyle></StyledButton>
        {saved && <><TextStyle>Saved!</TextStyle></>}
        {loadedPreset && <><TextStyle>Loaded!</TextStyle></>}
        {loaded && !loadedPreset && <>
            {Object.keys(settingsState!!).map(unitKey => {
                const unit = Number.parseInt(unitKey)
                const unitSettings = settingsState!![unit]
                return <ColumnLayout>
                    <TextStyle weight="bold">{unitSettings.Label}</TextStyle>
                    <RowLayout>
                        <input checked={unitSettings.Enabled} onChange={(e) => SetParam(unitSettings, "Enabled", e.target.checked)} type="checkbox" />
                        <TextStyle>Enabled</TextStyle>
                    </RowLayout>
                    {
                        unitSettings.Enabled && Object.keys(unitSettings.Items).map(itemKey => {
                            const item = Number.parseInt(itemKey)
                            const itemSettings = unitSettings.Items[item]
                            return <ColumnLayout>
                                <TextStyle weight="bold">{itemSettings.Label}</TextStyle>

                                <RowLayout>
                                    <input checked={itemSettings.Enabled} onChange={(e) => SetParam(itemSettings, "Enabled", e.target.checked)} type="checkbox" />
                                    <TextStyle>Enabled</TextStyle>
                                </RowLayout>

                                {itemSettings.Enabled && <>
                                    <RowLayout><TextStyle>New:</TextStyle><input onChange={(e) => SetParam(itemSettings, "New", Number.parseInt(e.target.value))} type="number" /></RowLayout>
                                    <RowLayout><TextStyle>Review:</TextStyle><input onChange={(e) => SetParam(itemSettings, "Review", Number.parseInt(e.target.value))} type="number" /></RowLayout>
                                    <RowLayout><TextStyle>SelectReviewBy:</TextStyle>
                                        <select onChange={(e) => SetParam(itemSettings, "SelectReviewBy", e.target.value as SelectReviewByOption)}>
                                            <option value={SelectReviewByOption.Due}>{SelectReviewByOption.Due}</option>
                                            <option value={SelectReviewByOption.Learning}>{SelectReviewByOption.Learning}</option>
                                            <option value={SelectReviewByOption.Review}>{SelectReviewByOption.Review}</option>
                                            {/* <option value={SelectReviewByOption.All}>{SelectReviewByOption.All}</option> */}
                                        </select>
                                    </RowLayout>
                                    <ColumnLayout>
                                        <TextStyle weight="bold">Tests:</TextStyle>
                                        {Object.keys(itemSettings.Tests).map(testKey => {
                                            var td = Number.parseInt(testKey)
                                            const testConfig = itemSettings.Tests[td]

                                            return <>
                                                <RowLayout>
                                                    <TextStyle>{testConfig.Label}</TextStyle>
                                                    <input checked={testConfig.Enabled} onChange={(e) => SetParam(testConfig, "Enabled", e.target.checked)} type="checkbox" />
                                                </RowLayout>

                                            </>
                                        })}
                                    </ColumnLayout>

                                </>}
                            </ColumnLayout>
                        })
                    }
                </ColumnLayout>
            })}
        </>}
    </ColumnLayout>
}