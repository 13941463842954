import { useContext } from "react"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { useQuery } from "@tanstack/react-query"
import { ContentTile } from "../Components/ModeTile"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { PostFeedResponse } from "../api"
import { TextStyle } from "../App"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { AnnotatedTextDisplay, SentenceDisplay } from "../Displays/TextStructureDisplay"
import { ExampleSentenceDisplay } from "../Components/ItemExamplesView"
import styled from "styled-components"

export const FeedView: React.FC = (props) => {
    return <>
        <FeedLoader />
    </>
}

export const FeedLoader: React.FC = (props) => {
    const api = useContext(ApiContext)

    const { data, error, isLoading } = useQuery({
        queryKey: ["feedPosts"],
        queryFn: () => api.FeedPosts().then(UnwrapApiCall)
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return <>
        {data?.map((post: PostFeedResponse) => (<>
            <ContentTile>
                <PostContainer>
                    <MetadataContainer>
                        <RowLayout style={{ justifyContent: 'center', alignItems: 'center', width: '20%' }}>
                            <img style={{ display: 'block' }} crossOrigin="anonymous" src={`${api.Settings?.ApiServer}/feed/avatar/${post.profileUuid}.png`} height={100} width={100} />
                            <ColumnLayout style={{ width: 'auto' }}>
                            <TextStyle weight='bold' size={1}>{post.handle}</TextStyle>
                            <TextStyle style={{ fontStyle: 'italic' }} size={1}>{post.createdAt.toLocaleString()}</TextStyle>
                        </ColumnLayout>
                    </RowLayout>
                    </MetadataContainer>
                    <ContentContainer>
                        <SegmentsForTextLoader text={post.content} />
                    </ContentContainer>
                </PostContainer>
            </ContentTile>
        </>))}
    </>
}

export const PostContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

export const MetadataContainer = styled.div`
    @media (width > 768px) {
        display: flex;
        flex-direction: row;
        width: calc(20% - 12px);
        gap: 24px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    @media (width < 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`

export const ContentContainer = styled.div`
    @media (width > 768px) {
    display: flex;
    flex-direction: column;
    width: calc(80% - 12px);
    gap: 24px;
    justify-content: center;
    align-items: center;
    }
    @media (width < 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`


export const SegmentsForTextLoader: React.FC<{ text: string }> = (props) => {
    const api = useContext(ApiContext)

    const { data, error, isLoading } = useQuery({
        queryKey: ["segmentsForText", props.text],
        queryFn: () => api.BreakdownText({ text: props.text }).then(UnwrapApiCall)
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return <>
        <ExampleSentenceDisplay text={props.text} sentenceRef={''} segments={data?.segments} />

    </>
}