import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'

import { ActionRecord } from '../models/ActionRecord';
import { ActionRecordAction } from '../models/ActionRecordAction';
import { AnswerRecord } from '../models/AnswerRecord';
import { ApiRoundItemData } from '../models/ApiRoundItemData';
import { ApiRoundRecord } from '../models/ApiRoundRecord';
import { AssistValidateRequest } from '../models/AssistValidateRequest';
import { BasicStatsQuery } from '../models/BasicStatsQuery';
import { BasicStatsResponse } from '../models/BasicStatsResponse';
import { BreakdownTextRequest } from '../models/BreakdownTextRequest';
import { CalculateSentenceDifficultyRequest } from '../models/CalculateSentenceDifficultyRequest';
import { DailyGoal } from '../models/DailyGoal';
import { DailyScoreRecord } from '../models/DailyScoreRecord';
import { DashboardResponse } from '../models/DashboardResponse';
import { DictionaryEntry } from '../models/DictionaryEntry';
import { DictionaryEntryDefinition } from '../models/DictionaryEntryDefinition';
import { DictionaryEntryDefinitionExamples } from '../models/DictionaryEntryDefinitionExamples';
import { DictionaryEntryType } from '../models/DictionaryEntryType';
import { DictionaryQueryResult } from '../models/DictionaryQueryResult';
import { EntryExtraData } from '../models/EntryExtraData';
import { EvaluateMeaningRequest } from '../models/EvaluateMeaningRequest';
import { EvaluateTranslationRequest2 } from '../models/EvaluateTranslationRequest2';
import { ExamplePackageQuery } from '../models/ExamplePackageQuery';
import { ExamplePackageResponse } from '../models/ExamplePackageResponse';
import { FeedPost } from '../models/FeedPost';
import { GenerateAudioRequest } from '../models/GenerateAudioRequest';
import { GenerateAudioResponse } from '../models/GenerateAudioResponse';
import { GenerateTextRequest } from '../models/GenerateTextRequest';
import { GeneratedTextStyle } from '../models/GeneratedTextStyle';
import { GetActionRecordRequest } from '../models/GetActionRecordRequest';
import { GetNotesRequest } from '../models/GetNotesRequest';
import { GetSentencesWithWord200ResponseInner } from '../models/GetSentencesWithWord200ResponseInner';
import { GraadeSentenceConstructionRequest } from '../models/GraadeSentenceConstructionRequest';
import { ItemData } from '../models/ItemData';
import { ItemHistory } from '../models/ItemHistory';
import { ItemRating } from '../models/ItemRating';
import { KeyContainer } from '../models/KeyContainer';
import { KeyRecord } from '../models/KeyRecord';
import { KeyRecordData } from '../models/KeyRecordData';
import { KeyRecordStats } from '../models/KeyRecordStats';
import { LanguageTextPair } from '../models/LanguageTextPair';
import { LearningItemsForSentenceQuery } from '../models/LearningItemsForSentenceQuery';
import { LearningItemsForSentenceResponse } from '../models/LearningItemsForSentenceResponse';
import { LibraryDashboardResponse } from '../models/LibraryDashboardResponse';
import { LookupQuery } from '../models/LookupQuery';
import { Message } from '../models/Message';
import { Messages } from '../models/Messages';
import { Note } from '../models/Note';
import { NoteRequest } from '../models/NoteRequest';
import { PairedItem } from '../models/PairedItem';
import { PartialChapter } from '../models/PartialChapter';
import { PartialParagraph } from '../models/PartialParagraph';
import { PartialSentence } from '../models/PartialSentence';
import { PartialTextContainer } from '../models/PartialTextContainer';
import { PartialTextContainerStructure } from '../models/PartialTextContainerStructure';
import { PartialTextNode } from '../models/PartialTextNode';
import { PartialTextStructure } from '../models/PartialTextStructure';
import { PartialTextStructureType } from '../models/PartialTextStructureType';
import { PickRoundRecordExcludeKeyofRoundRecordItems } from '../models/PickRoundRecordExcludeKeyofRoundRecordItems';
import { PostFeedResponse } from '../models/PostFeedResponse';
import { ProcessTextRequest } from '../models/ProcessTextRequest';
import { ProcessTextRequest2 } from '../models/ProcessTextRequest2';
import { ProcessTextResponse } from '../models/ProcessTextResponse';
import { ProcessedChapter } from '../models/ProcessedChapter';
import { ProcessedParagraph } from '../models/ProcessedParagraph';
import { ProcessedSentence } from '../models/ProcessedSentence';
import { ProcessedTextStructure } from '../models/ProcessedTextStructure';
import { ProcessedTextStructure2 } from '../models/ProcessedTextStructure2';
import { ProcessedTextStructure2Structure } from '../models/ProcessedTextStructure2Structure';
import { ProgressStats } from '../models/ProgressStats';
import { Query } from '../models/Query';
import { RecordSentenceKeyLastSeenKeyRecordStats } from '../models/RecordSentenceKeyLastSeenKeyRecordStats';
import { RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats } from '../models/RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats';
import { RegisterAccount } from '../models/RegisterAccount';
import { RoundItemData } from '../models/RoundItemData';
import { Sample } from '../models/Sample';
import { ScoreCard } from '../models/ScoreCard';
import { SegmentSentenceRequest } from '../models/SegmentSentenceRequest';
import { SegmentationOutput } from '../models/SegmentationOutput';
import { SegmentedText } from '../models/SegmentedText';
import { SelectItemConfiguration } from '../models/SelectItemConfiguration';
import { SelectLearningParameters } from '../models/SelectLearningParameters';
import { SelectMistakesParameters } from '../models/SelectMistakesParameters';
import { SelectReviewByOption } from '../models/SelectReviewByOption';
import { SelectUnitParameters } from '../models/SelectUnitParameters';
import { SelectedItems } from '../models/SelectedItems';
import { SentenceConstructionHelpRequest } from '../models/SentenceConstructionHelpRequest';
import { SentenceKeyLastSeen } from '../models/SentenceKeyLastSeen';
import { SentencePageQueryResponse } from '../models/SentencePageQueryResponse';
import { SentenceResult } from '../models/SentenceResult';
import { SentenceSearchKey } from '../models/SentenceSearchKey';
import { SentenceSearchType } from '../models/SentenceSearchType';
import { SentenceSegmentationResult } from '../models/SentenceSegmentationResult';
import { SentenceSelectionMethod } from '../models/SentenceSelectionMethod';
import { SentenceSelectionQuery } from '../models/SentenceSelectionQuery';
import { SentenceSelectionResult } from '../models/SentenceSelectionResult';
import { StatisticsQueryNewItemSummaryType } from '../models/StatisticsQueryNewItemSummaryType';
import { StatisticsQueryRoundSummaryType } from '../models/StatisticsQueryRoundSummaryType';
import { TextCategoryLink } from '../models/TextCategoryLink';
import { TextDifficulty } from '../models/TextDifficulty';
import { TextEntry } from '../models/TextEntry';
import { TextLength } from '../models/TextLength';
import { TextPair } from '../models/TextPair';
import { TextProgressSummary } from '../models/TextProgressSummary';
import { TextPromptType } from '../models/TextPromptType';
import { TextType } from '../models/TextType';
import { TranslateResponse } from '../models/TranslateResponse';
import { TranslateResponseCost } from '../models/TranslateResponseCost';
import { TranslateResponseOutput } from '../models/TranslateResponseOutput';
import { TranslateSentenceRequest } from '../models/TranslateSentenceRequest';
import { UnifiedLookupQueryResponse } from '../models/UnifiedLookupQueryResponse';
import { UnifiedLookupQueryUnit } from '../models/UnifiedLookupQueryUnit';
import { UserGradeState } from '../models/UserGradeState';
import { ObservableDefaultApi } from './ObservableAPI';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class PromiseDefaultApi {
    private api: ObservableDefaultApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param noteRequest 
     */
    public addNoteWithHttpInfo(noteRequest: NoteRequest, _options?: Configuration): Promise<HttpInfo<boolean>> {
        const result = this.api.addNoteWithHttpInfo(noteRequest, _options);
        return result.toPromise();
    }

    /**
     * @param noteRequest 
     */
    public addNote(noteRequest: NoteRequest, _options?: Configuration): Promise<boolean> {
        const result = this.api.addNote(noteRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public allNotesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<Note>>> {
        const result = this.api.allNotesWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public allNotes(_options?: Configuration): Promise<Array<Note>> {
        const result = this.api.allNotes(_options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExamplesWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<TranslateResponse>> {
        const result = this.api.assistExamplesWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExamples(query: Query, _options?: Configuration): Promise<TranslateResponse> {
        const result = this.api.assistExamples(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExplainWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistExplainWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistExplain(query: Query, _options?: Configuration): Promise<string> {
        const result = this.api.assistExplain(query, _options);
        return result.toPromise();
    }

    /**
     * @param graadeSentenceConstructionRequest 
     */
    public assistGradeSentenceConstructionWithHttpInfo(graadeSentenceConstructionRequest: GraadeSentenceConstructionRequest, _options?: Configuration): Promise<HttpInfo<any>> {
        const result = this.api.assistGradeSentenceConstructionWithHttpInfo(graadeSentenceConstructionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param graadeSentenceConstructionRequest 
     */
    public assistGradeSentenceConstruction(graadeSentenceConstructionRequest: GraadeSentenceConstructionRequest, _options?: Configuration): Promise<any> {
        const result = this.api.assistGradeSentenceConstruction(graadeSentenceConstructionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceConstructionHelpRequest 
     */
    public assistSentenceConstructionHelpWithHttpInfo(sentenceConstructionHelpRequest: SentenceConstructionHelpRequest, _options?: Configuration): Promise<HttpInfo<any>> {
        const result = this.api.assistSentenceConstructionHelpWithHttpInfo(sentenceConstructionHelpRequest, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceConstructionHelpRequest 
     */
    public assistSentenceConstructionHelp(sentenceConstructionHelpRequest: SentenceConstructionHelpRequest, _options?: Configuration): Promise<any> {
        const result = this.api.assistSentenceConstructionHelp(sentenceConstructionHelpRequest, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistTranslateWithHttpInfo(query: Query, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistTranslateWithHttpInfo(query, _options);
        return result.toPromise();
    }

    /**
     * @param query 
     */
    public assistTranslate(query: Query, _options?: Configuration): Promise<string> {
        const result = this.api.assistTranslate(query, _options);
        return result.toPromise();
    }

    /**
     * @param translateSentenceRequest 
     */
    public assistTranslateSentenceWithHttpInfo(translateSentenceRequest: TranslateSentenceRequest, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistTranslateSentenceWithHttpInfo(translateSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param translateSentenceRequest 
     */
    public assistTranslateSentence(translateSentenceRequest: TranslateSentenceRequest, _options?: Configuration): Promise<string> {
        const result = this.api.assistTranslateSentence(translateSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidateWithHttpInfo(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Promise<HttpInfo<TranslateResponse>> {
        const result = this.api.assistValidateWithHttpInfo(assistValidateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidate(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Promise<TranslateResponse> {
        const result = this.api.assistValidate(assistValidateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param messages 
     */
    public assistantChatWithHttpInfo(messages: Messages, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.assistantChatWithHttpInfo(messages, _options);
        return result.toPromise();
    }

    /**
     * @param messages 
     */
    public assistantChat(messages: Messages, _options?: Configuration): Promise<string> {
        const result = this.api.assistantChat(messages, _options);
        return result.toPromise();
    }

    /**
     * @param basicStatsQuery 
     */
    public basicStatsWithHttpInfo(basicStatsQuery: BasicStatsQuery, _options?: Configuration): Promise<HttpInfo<BasicStatsResponse>> {
        const result = this.api.basicStatsWithHttpInfo(basicStatsQuery, _options);
        return result.toPromise();
    }

    /**
     * @param basicStatsQuery 
     */
    public basicStats(basicStatsQuery: BasicStatsQuery, _options?: Configuration): Promise<BasicStatsResponse> {
        const result = this.api.basicStats(basicStatsQuery, _options);
        return result.toPromise();
    }

    /**
     * @param breakdownTextRequest 
     */
    public breakdownTextWithHttpInfo(breakdownTextRequest: BreakdownTextRequest, _options?: Configuration): Promise<HttpInfo<SentenceResult>> {
        const result = this.api.breakdownTextWithHttpInfo(breakdownTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param breakdownTextRequest 
     */
    public breakdownText(breakdownTextRequest: BreakdownTextRequest, _options?: Configuration): Promise<SentenceResult> {
        const result = this.api.breakdownText(breakdownTextRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public calculateScoringWithHttpInfo(_options?: Configuration): Promise<HttpInfo<ScoreCard>> {
        const result = this.api.calculateScoringWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public calculateScoring(_options?: Configuration): Promise<ScoreCard> {
        const result = this.api.calculateScoring(_options);
        return result.toPromise();
    }

    /**
     * @param calculateSentenceDifficultyRequest 
     */
    public calculateSentenceDifficultyWithHttpInfo(calculateSentenceDifficultyRequest: CalculateSentenceDifficultyRequest, _options?: Configuration): Promise<HttpInfo<number>> {
        const result = this.api.calculateSentenceDifficultyWithHttpInfo(calculateSentenceDifficultyRequest, _options);
        return result.toPromise();
    }

    /**
     * @param calculateSentenceDifficultyRequest 
     */
    public calculateSentenceDifficulty(calculateSentenceDifficultyRequest: CalculateSentenceDifficultyRequest, _options?: Configuration): Promise<number> {
        const result = this.api.calculateSentenceDifficulty(calculateSentenceDifficultyRequest, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public calculateTextProgressWithHttpInfo(ref: string, _options?: Configuration): Promise<HttpInfo<TextProgressSummary>> {
        const result = this.api.calculateTextProgressWithHttpInfo(ref, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public calculateTextProgress(ref: string, _options?: Configuration): Promise<TextProgressSummary> {
        const result = this.api.calculateTextProgress(ref, _options);
        return result.toPromise();
    }

    /**
     * @param keyContainer 
     */
    public checkKeyWithHttpInfo(keyContainer: KeyContainer, _options?: Configuration): Promise<HttpInfo<boolean>> {
        const result = this.api.checkKeyWithHttpInfo(keyContainer, _options);
        return result.toPromise();
    }

    /**
     * @param keyContainer 
     */
    public checkKey(keyContainer: KeyContainer, _options?: Configuration): Promise<boolean> {
        const result = this.api.checkKey(keyContainer, _options);
        return result.toPromise();
    }

    /**
     */
    public dashboardWithHttpInfo(_options?: Configuration): Promise<HttpInfo<DashboardResponse>> {
        const result = this.api.dashboardWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public dashboard(_options?: Configuration): Promise<DashboardResponse> {
        const result = this.api.dashboard(_options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public deleteTextWithHttpInfo(ref: string, _options?: Configuration): Promise<HttpInfo<void>> {
        const result = this.api.deleteTextWithHttpInfo(ref, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public deleteText(ref: string, _options?: Configuration): Promise<void> {
        const result = this.api.deleteText(ref, _options);
        return result.toPromise();
    }

    /**
     * @param image 
     */
    public evaluateImageWithHttpInfo(image: HttpFile, _options?: Configuration): Promise<HttpInfo<SentenceSegmentationResult>> {
        const result = this.api.evaluateImageWithHttpInfo(image, _options);
        return result.toPromise();
    }

    /**
     * @param image 
     */
    public evaluateImage(image: HttpFile, _options?: Configuration): Promise<SentenceSegmentationResult> {
        const result = this.api.evaluateImage(image, _options);
        return result.toPromise();
    }

    /**
     * @param evaluateMeaningRequest 
     */
    public evaluateMeaningWithHttpInfo(evaluateMeaningRequest: EvaluateMeaningRequest, _options?: Configuration): Promise<HttpInfo<number>> {
        const result = this.api.evaluateMeaningWithHttpInfo(evaluateMeaningRequest, _options);
        return result.toPromise();
    }

    /**
     * @param evaluateMeaningRequest 
     */
    public evaluateMeaning(evaluateMeaningRequest: EvaluateMeaningRequest, _options?: Configuration): Promise<number> {
        const result = this.api.evaluateMeaning(evaluateMeaningRequest, _options);
        return result.toPromise();
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciationWithHttpInfo(reference: string, audio: HttpFile, _options?: Configuration): Promise<HttpInfo<any>> {
        const result = this.api.evaluatePronunciationWithHttpInfo(reference, audio, _options);
        return result.toPromise();
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciation(reference: string, audio: HttpFile, _options?: Configuration): Promise<any> {
        const result = this.api.evaluatePronunciation(reference, audio, _options);
        return result.toPromise();
    }

    /**
     * @param evaluateTranslationRequest2 
     */
    public evaluateTranslation2WithHttpInfo(evaluateTranslationRequest2: EvaluateTranslationRequest2, _options?: Configuration): Promise<HttpInfo<number>> {
        const result = this.api.evaluateTranslation2WithHttpInfo(evaluateTranslationRequest2, _options);
        return result.toPromise();
    }

    /**
     * @param evaluateTranslationRequest2 
     */
    public evaluateTranslation2(evaluateTranslationRequest2: EvaluateTranslationRequest2, _options?: Configuration): Promise<number> {
        const result = this.api.evaluateTranslation2(evaluateTranslationRequest2, _options);
        return result.toPromise();
    }

    /**
     * @param examplePackageQuery 
     */
    public examplePackageWithHttpInfo(examplePackageQuery: ExamplePackageQuery, _options?: Configuration): Promise<HttpInfo<ExamplePackageResponse>> {
        const result = this.api.examplePackageWithHttpInfo(examplePackageQuery, _options);
        return result.toPromise();
    }

    /**
     * @param examplePackageQuery 
     */
    public examplePackage(examplePackageQuery: ExamplePackageQuery, _options?: Configuration): Promise<ExamplePackageResponse> {
        const result = this.api.examplePackage(examplePackageQuery, _options);
        return result.toPromise();
    }

    /**
     * @param apiRoundRecord 
     */
    public finishRoundWithHttpInfo(apiRoundRecord: ApiRoundRecord, _options?: Configuration): Promise<HttpInfo<any>> {
        const result = this.api.finishRoundWithHttpInfo(apiRoundRecord, _options);
        return result.toPromise();
    }

    /**
     * @param apiRoundRecord 
     */
    public finishRound(apiRoundRecord: ApiRoundRecord, _options?: Configuration): Promise<any> {
        const result = this.api.finishRound(apiRoundRecord, _options);
        return result.toPromise();
    }

    /**
     * @param generateAudioRequest 
     */
    public generateAudioWithHttpInfo(generateAudioRequest: GenerateAudioRequest, _options?: Configuration): Promise<HttpInfo<GenerateAudioResponse>> {
        const result = this.api.generateAudioWithHttpInfo(generateAudioRequest, _options);
        return result.toPromise();
    }

    /**
     * @param generateAudioRequest 
     */
    public generateAudio(generateAudioRequest: GenerateAudioRequest, _options?: Configuration): Promise<GenerateAudioResponse> {
        const result = this.api.generateAudio(generateAudioRequest, _options);
        return result.toPromise();
    }

    /**
     * @param generateTextRequest 
     */
    public generateTextWithHttpInfo(generateTextRequest: GenerateTextRequest, _options?: Configuration): Promise<HttpInfo<ProcessedTextStructure>> {
        const result = this.api.generateTextWithHttpInfo(generateTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param generateTextRequest 
     */
    public generateText(generateTextRequest: GenerateTextRequest, _options?: Configuration): Promise<ProcessedTextStructure> {
        const result = this.api.generateText(generateTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param getActionRecordRequest 
     */
    public getActionRecordsWithHttpInfo(getActionRecordRequest: GetActionRecordRequest, _options?: Configuration): Promise<HttpInfo<Array<ActionRecord>>> {
        const result = this.api.getActionRecordsWithHttpInfo(getActionRecordRequest, _options);
        return result.toPromise();
    }

    /**
     * @param getActionRecordRequest 
     */
    public getActionRecords(getActionRecordRequest: GetActionRecordRequest, _options?: Configuration): Promise<Array<ActionRecord>> {
        const result = this.api.getActionRecords(getActionRecordRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uuid 
     */
    public getImageWithHttpInfo(uuid: string, _options?: Configuration): Promise<HttpInfo<string>> {
        const result = this.api.getImageWithHttpInfo(uuid, _options);
        return result.toPromise();
    }

    /**
     * @param uuid 
     */
    public getImage(uuid: string, _options?: Configuration): Promise<string> {
        const result = this.api.getImage(uuid, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getItemHistoryWithHttpInfo(id: number, _options?: Configuration): Promise<HttpInfo<ItemHistory>> {
        const result = this.api.getItemHistoryWithHttpInfo(id, _options);
        return result.toPromise();
    }

    /**
     * @param id 
     */
    public getItemHistory(id: number, _options?: Configuration): Promise<ItemHistory> {
        const result = this.api.getItemHistory(id, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getKeyRecordsWithHttpInfo(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Promise<HttpInfo<Array<KeyRecord>>> {
        const result = this.api.getKeyRecordsWithHttpInfo(sentenceSelectionQuery, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getKeyRecords(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Promise<Array<KeyRecord>> {
        const result = this.api.getKeyRecords(sentenceSelectionQuery, _options);
        return result.toPromise();
    }

    /**
     * @param learningItemsForSentenceQuery 
     */
    public getLearningItemsForSentenceWithHttpInfo(learningItemsForSentenceQuery: LearningItemsForSentenceQuery, _options?: Configuration): Promise<HttpInfo<LearningItemsForSentenceResponse>> {
        const result = this.api.getLearningItemsForSentenceWithHttpInfo(learningItemsForSentenceQuery, _options);
        return result.toPromise();
    }

    /**
     * @param learningItemsForSentenceQuery 
     */
    public getLearningItemsForSentence(learningItemsForSentenceQuery: LearningItemsForSentenceQuery, _options?: Configuration): Promise<LearningItemsForSentenceResponse> {
        const result = this.api.getLearningItemsForSentence(learningItemsForSentenceQuery, _options);
        return result.toPromise();
    }

    /**
     * @param selectMistakesParameters 
     */
    public getMistakesWithHttpInfo(selectMistakesParameters: SelectMistakesParameters, _options?: Configuration): Promise<HttpInfo<SelectedItems>> {
        const result = this.api.getMistakesWithHttpInfo(selectMistakesParameters, _options);
        return result.toPromise();
    }

    /**
     * @param selectMistakesParameters 
     */
    public getMistakes(selectMistakesParameters: SelectMistakesParameters, _options?: Configuration): Promise<SelectedItems> {
        const result = this.api.getMistakes(selectMistakesParameters, _options);
        return result.toPromise();
    }

    /**
     * @param getNotesRequest 
     */
    public getNotesWithHttpInfo(getNotesRequest: GetNotesRequest, _options?: Configuration): Promise<HttpInfo<Array<Note>>> {
        const result = this.api.getNotesWithHttpInfo(getNotesRequest, _options);
        return result.toPromise();
    }

    /**
     * @param getNotesRequest 
     */
    public getNotes(getNotesRequest: GetNotesRequest, _options?: Configuration): Promise<Array<Note>> {
        const result = this.api.getNotes(getNotesRequest, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSearchKeysWithHttpInfo(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Promise<HttpInfo<Array<string>>> {
        const result = this.api.getSearchKeysWithHttpInfo(sentenceSelectionQuery, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSearchKeys(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Promise<Array<string>> {
        const result = this.api.getSearchKeys(sentenceSelectionQuery, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSentencesBySelectionWithHttpInfo(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Promise<HttpInfo<SentenceSelectionResult>> {
        const result = this.api.getSentencesBySelectionWithHttpInfo(sentenceSelectionQuery, _options);
        return result.toPromise();
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSentencesBySelection(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Promise<SentenceSelectionResult> {
        const result = this.api.getSentencesBySelection(sentenceSelectionQuery, _options);
        return result.toPromise();
    }

    /**
     * @param count 
     * @param page 
     */
    public getSentencesByUnitOrderWithHttpInfo(count: number, page: number, _options?: Configuration): Promise<HttpInfo<SentencePageQueryResponse>> {
        const result = this.api.getSentencesByUnitOrderWithHttpInfo(count, page, _options);
        return result.toPromise();
    }

    /**
     * @param count 
     * @param page 
     */
    public getSentencesByUnitOrder(count: number, page: number, _options?: Configuration): Promise<SentencePageQueryResponse> {
        const result = this.api.getSentencesByUnitOrder(count, page, _options);
        return result.toPromise();
    }

    /**
     * @param word 
     */
    public getSentencesWithWordWithHttpInfo(word: string, _options?: Configuration): Promise<HttpInfo<Array<GetSentencesWithWord200ResponseInner>>> {
        const result = this.api.getSentencesWithWordWithHttpInfo(word, _options);
        return result.toPromise();
    }

    /**
     * @param word 
     */
    public getSentencesWithWord(word: string, _options?: Configuration): Promise<Array<GetSentencesWithWord200ResponseInner>> {
        const result = this.api.getSentencesWithWord(word, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public getTextWithHttpInfo(ref: string, _options?: Configuration): Promise<HttpInfo<ProcessedTextStructure>> {
        const result = this.api.getTextWithHttpInfo(ref, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public getText(ref: string, _options?: Configuration): Promise<ProcessedTextStructure> {
        const result = this.api.getText(ref, _options);
        return result.toPromise();
    }

    /**
     */
    public getTextCategoriesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<string>>> {
        const result = this.api.getTextCategoriesWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public getTextCategories(_options?: Configuration): Promise<Array<string>> {
        const result = this.api.getTextCategories(_options);
        return result.toPromise();
    }

    /**
     */
    public getTextCategoryLinksWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<TextCategoryLink>>> {
        const result = this.api.getTextCategoryLinksWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public getTextCategoryLinks(_options?: Configuration): Promise<Array<TextCategoryLink>> {
        const result = this.api.getTextCategoryLinks(_options);
        return result.toPromise();
    }

    /**
     */
    public getTextsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<TextEntry>>> {
        const result = this.api.getTextsWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public getTexts(_options?: Configuration): Promise<Array<TextEntry>> {
        const result = this.api.getTexts(_options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public gradeTextWithHttpInfo(ref: string, _options?: Configuration): Promise<HttpInfo<{ [key: string]: number; }>> {
        const result = this.api.gradeTextWithHttpInfo(ref, _options);
        return result.toPromise();
    }

    /**
     * @param ref 
     */
    public gradeText(ref: string, _options?: Configuration): Promise<{ [key: string]: number; }> {
        const result = this.api.gradeText(ref, _options);
        return result.toPromise();
    }

    /**
     * @param unitId 
     * @param itemType 
     * @param grade 
     */
    public itemViewByGradeWithHttpInfo(unitId: number, itemType: number, grade: number, _options?: Configuration): Promise<HttpInfo<{ [key: string]: PairedItem; }>> {
        const result = this.api.itemViewByGradeWithHttpInfo(unitId, itemType, grade, _options);
        return result.toPromise();
    }

    /**
     * @param unitId 
     * @param itemType 
     * @param grade 
     */
    public itemViewByGrade(unitId: number, itemType: number, grade: number, _options?: Configuration): Promise<{ [key: string]: PairedItem; }> {
        const result = this.api.itemViewByGrade(unitId, itemType, grade, _options);
        return result.toPromise();
    }

    /**
     */
    public libraryDashboardWithHttpInfo(_options?: Configuration): Promise<HttpInfo<LibraryDashboardResponse>> {
        const result = this.api.libraryDashboardWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public libraryDashboard(_options?: Configuration): Promise<LibraryDashboardResponse> {
        const result = this.api.libraryDashboard(_options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public lookupQueryWithHttpInfo(lookupQuery: LookupQuery, _options?: Configuration): Promise<HttpInfo<DictionaryQueryResult>> {
        const result = this.api.lookupQueryWithHttpInfo(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public lookupQuery(lookupQuery: LookupQuery, _options?: Configuration): Promise<DictionaryQueryResult> {
        const result = this.api.lookupQuery(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     */
    public postFeedWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<PostFeedResponse>>> {
        const result = this.api.postFeedWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public postFeed(_options?: Configuration): Promise<Array<PostFeedResponse>> {
        const result = this.api.postFeed(_options);
        return result.toPromise();
    }

    /**
     * @param actionRecord 
     */
    public processActionRecordWithHttpInfo(actionRecord: Array<ActionRecord>, _options?: Configuration): Promise<HttpInfo<boolean>> {
        const result = this.api.processActionRecordWithHttpInfo(actionRecord, _options);
        return result.toPromise();
    }

    /**
     * @param actionRecord 
     */
    public processActionRecord(actionRecord: Array<ActionRecord>, _options?: Configuration): Promise<boolean> {
        const result = this.api.processActionRecord(actionRecord, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest 
     */
    public processTextWithHttpInfo(processTextRequest: ProcessTextRequest, _options?: Configuration): Promise<HttpInfo<ProcessTextResponse>> {
        const result = this.api.processTextWithHttpInfo(processTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest 
     */
    public processText(processTextRequest: ProcessTextRequest, _options?: Configuration): Promise<ProcessTextResponse> {
        const result = this.api.processText(processTextRequest, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest2 
     */
    public processText2WithHttpInfo(processTextRequest2: ProcessTextRequest2, _options?: Configuration): Promise<HttpInfo<ProcessedTextStructure2>> {
        const result = this.api.processText2WithHttpInfo(processTextRequest2, _options);
        return result.toPromise();
    }

    /**
     * @param processTextRequest2 
     */
    public processText2(processTextRequest2: ProcessTextRequest2, _options?: Configuration): Promise<ProcessedTextStructure2> {
        const result = this.api.processText2(processTextRequest2, _options);
        return result.toPromise();
    }

    /**
     * @param registerAccount 
     */
    public registerWithHttpInfo(registerAccount: RegisterAccount, _options?: Configuration): Promise<HttpInfo<boolean>> {
        const result = this.api.registerWithHttpInfo(registerAccount, _options);
        return result.toPromise();
    }

    /**
     * @param registerAccount 
     */
    public register(registerAccount: RegisterAccount, _options?: Configuration): Promise<boolean> {
        const result = this.api.register(registerAccount, _options);
        return result.toPromise();
    }

    /**
     */
    public reviewsDueTodayWithHttpInfo(_options?: Configuration): Promise<HttpInfo<{ [key: string]: number; }>> {
        const result = this.api.reviewsDueTodayWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public reviewsDueToday(_options?: Configuration): Promise<{ [key: string]: number; }> {
        const result = this.api.reviewsDueToday(_options);
        return result.toPromise();
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentencesWithHttpInfo(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Promise<HttpInfo<SentenceSegmentationResult>> {
        const result = this.api.segmentSentencesWithHttpInfo(segmentSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentences(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Promise<SentenceSegmentationResult> {
        const result = this.api.segmentSentences(segmentSentenceRequest, _options);
        return result.toPromise();
    }

    /**
     * @param selectLearningParameters 
     */
    public selectCurrentLearningWithHttpInfo(selectLearningParameters: SelectLearningParameters, _options?: Configuration): Promise<HttpInfo<SelectedItems>> {
        const result = this.api.selectCurrentLearningWithHttpInfo(selectLearningParameters, _options);
        return result.toPromise();
    }

    /**
     * @param selectLearningParameters 
     */
    public selectCurrentLearning(selectLearningParameters: SelectLearningParameters, _options?: Configuration): Promise<SelectedItems> {
        const result = this.api.selectCurrentLearning(selectLearningParameters, _options);
        return result.toPromise();
    }

    /**
     * @param selectUnitParameters 
     */
    public selectUnitsWithHttpInfo(selectUnitParameters: SelectUnitParameters, _options?: Configuration): Promise<HttpInfo<SelectedItems>> {
        const result = this.api.selectUnitsWithHttpInfo(selectUnitParameters, _options);
        return result.toPromise();
    }

    /**
     * @param selectUnitParameters 
     */
    public selectUnits(selectUnitParameters: SelectUnitParameters, _options?: Configuration): Promise<SelectedItems> {
        const result = this.api.selectUnits(selectUnitParameters, _options);
        return result.toPromise();
    }

    /**
     */
    public sentenceKeyRecordStatsWithHttpInfo(_options?: Configuration): Promise<HttpInfo<RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats>> {
        const result = this.api.sentenceKeyRecordStatsWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public sentenceKeyRecordStats(_options?: Configuration): Promise<RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats> {
        const result = this.api.sentenceKeyRecordStats(_options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public unifiedLookupWithHttpInfo(lookupQuery: LookupQuery, _options?: Configuration): Promise<HttpInfo<UnifiedLookupQueryResponse>> {
        const result = this.api.unifiedLookupWithHttpInfo(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     * @param lookupQuery 
     */
    public unifiedLookup(lookupQuery: LookupQuery, _options?: Configuration): Promise<UnifiedLookupQueryResponse> {
        const result = this.api.unifiedLookup(lookupQuery, _options);
        return result.toPromise();
    }

    /**
     */
    public viewGradesWithHttpInfo(_options?: Configuration): Promise<HttpInfo<Array<UserGradeState>>> {
        const result = this.api.viewGradesWithHttpInfo(_options);
        return result.toPromise();
    }

    /**
     */
    public viewGrades(_options?: Configuration): Promise<Array<UserGradeState>> {
        const result = this.api.viewGrades(_options);
        return result.toPromise();
    }


}



