/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemData } from '../models/ItemData';
import { SentenceResult } from '../models/SentenceResult';
import { HttpFile } from '../http/http';

export class ExamplePackageResponse {
    'words': Array<ItemData>;
    'sentences': Array<SentenceResult>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "words",
            "baseName": "words",
            "type": "Array<ItemData>",
            "format": ""
        },
        {
            "name": "sentences",
            "baseName": "sentences",
            "type": "Array<SentenceResult>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ExamplePackageResponse.attributeTypeMap;
    }

    public constructor() {
    }
}

